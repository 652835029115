import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Topbar from './Topbar';
import ModalEndorser from './ModalEndorser';
import ModalWelcome from './ModalWelcome';

const { GATSBY_VERTICAL } = process.env;

const Layout = ({ children }) => {
  const user = useSelector((state) => state.user);

  const [welcomeModalOpen, setWelcomeModalOpen] = useState(false);
  const [endorserModalOpen, setEndorserModalOpen] = useState(false);

  const requestEndorserModalClose = () => {
    setEndorserModalOpen(false);
    if (localStorage.getItem('newUser') === 'true') setWelcomeModalOpen(true);
  };

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      localStorage.getItem('newUser') === null ||
      localStorage.getItem('newUser') === 'false'
    )
      return;

    if (
      ['CRUISES', 'DEKRA', 'VACCINE', 'VULCAIN'].includes(GATSBY_VERTICAL) ||
      user.role !== 'ADMIN'
    )
      setWelcomeModalOpen(true);
    else if (user.endorser === 'default') setEndorserModalOpen(true);
    else setWelcomeModalOpen(true);
  }, []);

  return (
    <>
      {user.authenticated && (
        <div>
          <Topbar setEndorserModalOpen={setEndorserModalOpen} />
          {children}
          {welcomeModalOpen && (
            <ModalWelcome open={welcomeModalOpen} requestClose={() => setWelcomeModalOpen(false)} />
          )}
          {endorserModalOpen && (
            <ModalEndorser
              open={endorserModalOpen}
              requestClose={() => requestEndorserModalClose()}
            />
          )}
        </div>
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
