/* eslint-disable */
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

const multiLingual = () => {
  switch (process.env.GATSBY_DEPLOYMENT) {
    case 'PREPROD':
    case 'PROD':
      return ['VULCAIN', 'DEKRA'].includes(process.env.GATSBY_VERTICAL) ? true : false;
    default:
      return true;
  }
};

const resources = {
  'es-ES': {
    greenshield: require('./locales/es-ES/greenshield.json'),
  },
  'fr-FR': {
    common: require('./locales/fr-FR/common.json'),
    countries: require('./locales/fr-FR/countries.json'),
    states: require('./locales/fr-FR/states.json'),
    'sign-in': require('./locales/fr-FR/sign-in.json'),
    'sign-up': require('./locales/fr-FR/sign-up.json'),
    ftg: require('./locales/fr-FR/ftg.json'),
    hospitality: require('./locales/fr-FR/hospitality.json'),
    cars: require('./locales/fr-FR/cars.json'),
    venue: require('./locales/fr-FR/venue.json'),
    schools: require('./locales/fr-FR/schools.json'),
    cruises: require('./locales/fr-FR/cruises.json'),
    dekra: require('./locales/fr-FR/dekra.json'),
    vulcain: require('./locales/fr-FR/vulcain.json'),
    vaccine: require('./locales/fr-FR/vaccine.json'),
    dashboard: require('./locales/fr-FR/dashboard.json'),
    facility: require('./locales/fr-FR/facility.json'),
    users: require('./locales/fr-FR/users.json'),
    greenshield: require('./locales/fr-FR/greenshield.json'),
    equilibre: require('./locales/fr-FR/equilibre.json')
  },
  'en-US': {
    common: require('./locales/en-US/common.json'),
    countries: require('./locales/en-US/countries.json'),
    states: require('./locales/en-US/states.json'),
    'sign-in': require('./locales/en-US/sign-in.json'),
    'sign-up': require('./locales/en-US/sign-up.json'),
    ftg: require('./locales/en-US/ftg.json'),
    hospitality: require('./locales/en-US/hospitality.json'),
    cars: require('./locales/en-US/cars.json'),
    venue: require('./locales/en-US/venue.json'),
    schools: require('./locales/en-US/schools.json'),
    cruises: require('./locales/en-US/cruises.json'),
    dekra: require('./locales/en-US/dekra.json'),
    vulcain: require('./locales/en-US/vulcain.json'),
    vaccine: require('./locales/en-US/vaccine.json'),
    dashboard: require('./locales/en-US/dashboard.json'),
    facility: require('./locales/en-US/facility.json'),
    users: require('./locales/en-US/users.json'),
    greenshield: require('./locales/en-US/greenshield.json'),
    equilibre: require('./locales/en-US/equilibre.json')
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    resources: multiLingual() ? { ...resources } : { 'en-US': resources['en-US'] },
    defaultNS: 'common',
    returnObjects: true,
    interpolation: { escapeValue: false },
    react: { useSuspense: true },
  });

export default i18next;
