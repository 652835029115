import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Aside, Button } from 'src/app/components';

const { GATSBY_DEPLOYMENT } = process.env;

const Menu = ({ open, requestClose, sign, signOut }) => {
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation('vulcain');
  const [dropdownOpen, setDropdownOpen] = useState(-1);

  const openChatbot = () => {
    document.getElementById('vulcain-chatbot').classList.add('open');
    requestClose();
  };

  return (
    <Aside title="Menu" open={open} requestClose={requestClose}>
      <Aside.Header closeButton requestClose={requestClose} />
      <Aside.Body>
        <nav className="text-center">
          <div className="relative" role="dialog" onMouseLeave={() => setDropdownOpen(-1)}>
            <button
              type="button"
              onClick={() => setDropdownOpen(1)}
              className="h-16 text-secondary hover:text-opacity-50 focus:text-opacity-50 focus:outline-none"
            >
              <div className="flex items-center justify-center w-full h-full px-4 rounded-full">
                <small className="capitalize">{i18n.language}</small>
                <svg viewBox="0 0 8 5" className="w-4 h-4 pl-1 fill-current">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.64072 4.84701L0.143713 1.35001C-0.0479042 1.14641 -0.0479042 0.835035 0.143713 0.643418C0.335329 0.451802 0.646707 0.451802 0.850299 0.643418L4 3.79312L7.1497 0.643418C7.34132 0.451802 7.66467 0.451802 7.85629 0.643418C8.0479 0.835035 8.0479 1.14641 7.85629 1.35001L4.34731 4.84701C4.15569 5.03863 3.84431 5.03863 3.64072 4.84701Z"
                  />
                </svg>
              </div>
            </button>
            <div
              className="absolute bg-white border"
              style={{
                top: '4rem',
                left: '82px',
                display: dropdownOpen === 1 ? 'block' : 'none',
              }}
            >
              <ul className="flex flex-col items-center w-48">
                <li className="w-full">
                  <button
                    type="button"
                    tabIndex={dropdownOpen === 1 ? '0' : '-1'}
                    onClick={() => {
                      i18n.changeLanguage('en-US');
                      setDropdownOpen(-1);
                    }}
                    className="flex items-center justify-center w-full py-2 whitespace-nowrap hover:bg-secondary hover:bg-opacity-10 focus:bg-secondary focus:bg-opacity-10 focus:outline-none"
                  >
                    English
                  </button>
                </li>
                <li className="w-full">
                  <button
                    type="button"
                    tabIndex={dropdownOpen === 1 ? '0' : '-1'}
                    onClick={() => {
                      i18n.changeLanguage('fr-FR');
                      setDropdownOpen(-1);
                    }}
                    onBlur={() => setDropdownOpen(-1)}
                    className="flex items-center justify-center w-full py-2 whitespace-nowrap hover:bg-secondary hover:bg-opacity-10 focus:bg-secondary focus:bg-opacity-10 focus:outline-none"
                  >
                    Français
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col px-4 pt-12 space-y-4">
            {user.authenticated ? (
              <>
                <Button as="link" variant="secondary" to="/dashboard/">
                  {t('navigation.buttons.dashboard')}
                </Button>
                <Button onClick={() => signOut()}>{t('navigation.buttons.signout')}</Button>
              </>
            ) : (
              <>
                <Button
                  variant="secondary"
                  gtag="Click_signin"
                  onClick={() => sign(false)}
                  disabled={user.isAuthenticating}
                  loading={user.isAuthenticating}
                >
                  {t('navigation.buttons.signin')}
                </Button>
                <Button
                  gtag="Click_signup"
                  onClick={() => sign(true)}
                  disabled={user.isAuthenticating}
                  loading={user.isAuthenticating}
                >
                  {t('navigation.buttons.getStarted')}
                </Button>
              </>
            )}
          </div>
        </nav>
      </Aside.Body>
    </Aside>
  );
};

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
};

export default Menu;
